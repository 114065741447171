import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function authentication(){
  return false;
}
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  
  routes: [
    {
      name:'Test',
      path:'/test',
      component:()=>import('@/client/Test.vue'),
    },
   /*  {
      name:'Admin',
      path:'/admin',
      component:()=>import('@/admin/Login.vue'),
    }, */
   {
    name: 'Login',
    path: '',
    component: () => import('@/client/Login.vue')

   },
   {
    path: '/login',
    redirect:{
      name:'Login',
    }

   },
  
    {
      path: '/',
       component: () => import('@/views/Index'),
      children: [
        
      /*   {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/views/Dashboard'),
        } */,

        {
          name: 'Utilisateurs',
          path: 'utilisateurs',
          component: () => import('@/admin/Users'),
        },
        {
          name:'Dashboard',
          path:'/client',
          component: () => import('@/client/Client')
      
        },
        /* {
          name: 'Mon Compte',
          path: 'compte',
          component: () => import('@/pages/Account'),
        } */,
      
        {
          name: 'Profils',
          path: 'profils',
          component: () => import('@/admin/Profils'),
        },
      ],
    },
    
  ],
})
