import Vue from 'vue'
import Vuex from 'vuex'
import API from '../src/api-config.js';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedValue: null,
    label:"Selectionner un Graphe",
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
    ChartItems:[],
    SizeItems:[],
    DataItem:[],
    DashboardItems:[],
    CurrentActiveDashboard:
    {
      id:null,
      libelle:"",
      active:true
    }
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SwitchDashboard(state,item)
    {
      state.CurrentActiveDashboard.id = item.id;
      state.CurrentActiveDashboard.libelle = item.libelle;
    },
    LoadSelectItems(state,id)
    {
      state.DataItem=[];
      let consumedAPI="graphe/liste";
      let endpoint= API.APIendpoint;
      Vue.http.get(endpoint+consumedAPI, {params: {'du_id':id},headers: {'token':localStorage.getItem('token')}}).then(response => {
           let responseHandle = response.body;
           responseHandle.data.forEach(e => {
             let temp ={
               value: parseInt(`${e.id}`) ,
               text :`${e.libelle}`,
               disabled: `${e.statut}` == 'true'
             }
             state.DataItem.push(temp);
 
         });
           }, response => {
           //callback error
           
         });

    },
    DeleteInDashboardItems(state,item)
    {
      if(item.id == state.CurrentActiveDashboard.id)
      {
        state.CurrentActiveDashboard.id=null;
        state.CurrentActiveDashboard.libelle=null;
        state.DataItem=[];
        state.ChartItems=[];
      }
      state.DashboardItems = state.DashboardItems.filter(
        (element) => element.id != item.id
      );

     
      
      
    },
    AddInDashboardItems(state,item)
    {
      state.DashboardItems.push(item);
    },
    LoadDashboardItems(state,item){
      state.DashboardItems = item;

    },
    FilterChartItems(state,id)
    {
      let SIZE_MIN=4;
      for(let i = 0 ; i < state.SizeItems.length ;i++)
      {
        if(state.SizeItems[i].id == id )
        {
          state.SizeItems[i].size = SIZE_MIN;
        break;
        }
      }
      state.ChartItems = state.ChartItems.filter((item)=>{
        return item.id != id;
      });
    },
    LoadCharts(state,DashboardID)
    {
      state.SizeItems=[];
      let endpoint= API.APIendpoint;
      let DashboardAPI="graphe/dashboard";
      Vue.http.get(endpoint+DashboardAPI,{params: {'du_id': DashboardID},headers: {'token':localStorage.getItem('token')}}).then(response => {
        //console.log(response.body.data);
        state.ChartItems = response.body.data;
        state.ChartItems.forEach(e => {
          let temp ={
            id: parseInt(`${e.id}`) ,
            size :parseInt(`${e.size}`),
          }
         state.SizeItems.push(temp);
         

      }); 
        }, response => {
            //callback error
      
       });
    },
    ChartSelected(state,id)
    {
     /*  console.log("here")
      console.log(id); */
      state.selectedValue = id;
      //console.log(state.selectedValue);
      let consumedAPI = "graphe/data";
      let endpoint= API.APIendpoint;
      Vue.http.get(endpoint + consumedAPI, {
          params: { id: id },
          headers: { token: localStorage.getItem('token') },
        })
        .then(
          (response) => {
            //console.log(response.body.data);
           let graphe_data = response.body.data;
            let temp = {
              id: parseInt(`${graphe_data.id}`),
              size: parseInt(`${graphe_data.size}`),
            };
              state.SizeItems.push(temp);

            state.ChartItems.push(graphe_data);
            state.DataItem[id - 1].disabled = true;
            state.selectedValue = null;
            state.label = "Selectionner un Graphe";
           
            
          },
          (response) => {
            //callback error
          }
        );
       let DashboardAPI= "graphe/dashboard";
       Vue.http.post(
          endpoint + DashboardAPI,
          { graphe_id: state.selectedValue, du_id: state.CurrentActiveDashboard.id },
          { headers: { token: localStorage.getItem('token')} }
        )
        .then(
          (response) => {},
          (response) => {
            //callback error
          }
        ); 
    }
  },
})

